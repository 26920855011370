import React, { useState } from 'react'
import * as S from './styles'
import { Input, InputSelect, TableComponet, Titulo } from '../../components'
import { Button, CircularProgress, Grid, Tooltip } from '@mui/material'
import { api, Utils } from '../../utilities'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import PerfisService from '../../service/PerfisService'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { FilterAltOff } from '@mui/icons-material'

function SincronizarEmpresas(props) {
  const { idUsuarioAtualizacao, empresas, selected, setSelected, user } = props
  const [empresasAdmin, setEmpresasAdmin] = useState([])

  const [loading, setLoading] = useState(false)

  const [statePerfilBusca, setStatePerfilBusca] = useState({
    idUsuario: idUsuarioAtualizacao, //campo obrigatorio
    idEmpresa: null, //filtro
    descricao: null, //filtro
    ativo: null, //null, true ou false
    menu: null,
    submenu: null,
  })
  const [perfils, setPefils] = useState([])

  const headersSemcadastro = [
    {
      id: 'codigoPerfil',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'descricao',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'empresaFantasia',
      label: 'Empresa Fantasia',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
  ]

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = perfils.map((n) => {
        n.idUsuarioAtualizacao = props.user.idUsuario
        return n
      })
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClickSelected = (event, relatorio) => {
    const selectedIndex = selected.findIndex(
      (selectedRelatorio) => selectedRelatorio.idPerfil === relatorio.idPerfil,
    )
    let newSelected = []

    if (selectedIndex === -1) {
      relatorio.idUsuarioAtualizacao = props.user.idUsuario
      newSelected = [...selected, relatorio]
    } else {
      newSelected = selected.filter(
        (selectedRelatorio) =>
          selectedRelatorio.idPerfil !== relatorio.idPerfil,
      )
    }
    setSelected(newSelected)
  }

  function buscarMobioh() {
    setLoading(true)

    let userTipo = Utils.isNotNull(user) ? user.tipo : null

    if (userTipo === 'ADMIN_MOBIOH') {
      PerfisService.buscarPerfilClienteAdmin(statePerfilBusca)
        .then((data) => {
          setLoading(false)
          if (Utils.isArrayNotEmpty(data)) {
            let newSelected = selected.filter((item) => {
              // Verificar se algum perfil em "data" tem o mesmo idEmpresa do item
              return !data.some((perfil) => perfil.idEmpresa === item.idEmpresa)
            })

            // Atualize o estado com os perfis filtrados
            setPefils([...newSelected, ...data])
          } else {
            setPefils([])
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os Perfis!', {
            theme: 'colored',
          })
        })

      return
    }

    PerfisService.buscarPerfilCliente(statePerfilBusca)
      .then((data) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          let newSelected = selected.filter((item) => {
            // Verificar se algum perfil em "data" tem o mesmo idEmpresa do item
            return !data.some((perfil) => perfil.idEmpresa === item.idEmpresa)
          })

          // Atualize o estado com os perfis filtrados
          setPefils([...newSelected, ...data])
        } else {
          setPefils([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Perfis!', {
          theme: 'colored',
        })
      })
  }

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        let userTipo = Utils.isNotNull(user) ? user.tipo : null

        if (userTipo !== 'ADMIN_MOBIOH') {
          return
        }
        setLoading(true)

        api.post('/empresa/admin/mobioh/dados').then(({ data }) => {
          setLoading(false)
          if (Utils.isNotObjectEmpty(data)) {
            setEmpresasAdmin(data.empresas)
          }
        })
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  return (
    <S.Container>
      <Titulo>
        <div>Vincular Perfil</div>
      </Titulo>

      <Grid container padding={1} spacing={2} marginBottom={1}>
        <Grid xs={5} item>
          <Input
            label="Pesquisar"
            id={'descricao'}
            // error={validacao(search)}
            // helperText={
            //   validacao(search) ? 'Essa informação é obrigatória' : ''
            // }
            value={statePerfilBusca.descricao}
            onChange={(e) => {
              setStatePerfilBusca((state) => ({
                ...state,
                descricao: e.target.value,
              }))
            }}
            placeholder="Escreva a descrição do Perfil"
            size="small"
          />
        </Grid>

        <Grid xs={3} item>
          <InputSelect
            value={statePerfilBusca.idEmpresa}
            setValue={(value) => {
              setStatePerfilBusca((state) => ({
                ...state,
                idEmpresa: value,
              }))
            }}
            options={empresas ? empresas : empresasAdmin}
            label="Empresas"
            id="idEmpresa"
            descricao="fantasia"
            size="small"
            sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
          />
        </Grid>

        <Grid xs={2} item display={'flex'}>
          <Button
            className="confirm-button"
            variant="contained"
            size="large"
            startIcon={
              loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                <SearchOutlinedIcon />
              )
            }
            disabled={loading}
            sx={{ height: '39px' }}
            onClick={buscarMobioh}
          >
            Buscar
          </Button>

          <Tooltip
            title={'Limpar Filtros'}
            placement="top"
            style={{ fontSize: '20px !important' }}
          >
            <Button
              className="confirm-button"
              variant="text"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <FilterAltOff />
                )
              }
              disabled={loading}
              sx={{ height: '39px', color: '#C4C4C4 !important' }}
              onClick={() =>
                setStatePerfilBusca({
                  idUsuario: idUsuarioAtualizacao, //campo obrigatorio
                  idEmpresa: null, //filtro
                  descricao: null, //filtro
                  ativo: null, //null, true ou false
                  menu: null,
                  submenu: null,
                })
              }
            ></Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container padding={2}>
        <Grid xs={12} item>
          <TableComponet
            headers={headersSemcadastro}
            data={perfils}
            labelCaption="Nenhum Empresa Encontrado"
            labelTable="Lista de Perfils"
            status="ativo"
            statusLabelTrue="Ativo"
            statusLabelFalse="Inativo"
            loading={loading}
            selected={selected}
            selectedName="idPerfil"
            handleClickSelected={handleClickSelected}
            handleSelectAllClick={handleSelectAllClick}
          />
        </Grid>
      </Grid>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
  empresas: store.loginState.user.empresas,
})

export default connect(mapStateToProps)(SincronizarEmpresas)
